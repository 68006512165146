import React from 'react';
import { IonItem, IonRow, IonCol, IonGrid, IonButton, IonAlert } from '@ionic/react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, SmallText, Spacer, NormalText } from '../../components/common';
import { connect } from 'react-redux';
import { forwardTo, visaEndingDrawText, isEmptyObject } from '../../lib/utils';
import Basket from '../../lib/basket';
import { withRouter } from 'react-router';
import './index.css';
import AmountPaid from '../../components/amountPaid';

class BillCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: null,
      restaurantId: null,
    };
  }

  componentDidMount() {
    const { billStatus } = this.props;
    if (isEmptyObject(billStatus.table)) {
      forwardTo('/dashboard');
    }
  }

  drawContent = (__, tableItems) => {
    return (
      <>
        <IonRow className="inline-block">
          <IonCol size="5" className="paddLR mr-5">
            <SmallText tag="strong">{__('Billed at')}:</SmallText>
          </IonCol>
          <IonCol className="righted self-aligned">
            <SmallText>{'restaurant_name'}</SmallText>
          </IonCol>
        </IonRow>
        <IonRow className="block">
          <IonCol size="5" className="paddLR mr-5">
            <SmallText tag="strong">{__('Table number')}:</SmallText>
          </IonCol>
          <IonCol className="righted self-aligned">
            <SmallText>{'table_name'}</SmallText>
          </IonCol>
        </IonRow>
      </>
    );
  };

  backHandler = () => {
    Basket.reset();
    forwardTo('/table-bill-pay');
  };

  render() {
    const { __, billStatus, cards } = this.props;

    return (
      <Loading>
        <Layout headerTitle={__('Order Completed')} backHandler={this.backHandler}>
          <Title>{__('Payment Confirmation')}</Title>
          <Spacer size={1} />
          <div className="bill-box">
            <IonRow>
              <IonCol size="5" className="self-centered text-start">
                <SmallText tag="strong">{__('From')}:</SmallText>
              </IonCol>
              <IonCol style={{ textAlign: 'end' }} className="self-centered text-start">
                <SmallText>{billStatus.table?.restaurant_name}</SmallText>
              </IonCol>
            </IonRow>
            <Spacer size={1} />
            <IonRow>
              <IonCol size="5" className="self-centered text-start">
                <SmallText tag="strong">{__('Table number')}:</SmallText>
              </IonCol>
              <IonCol style={{ textAlign: 'end' }} className="self-centered text-start">
                <SmallText>{billStatus.table?.table_number}</SmallText>
              </IonCol>
            </IonRow>
            <Spacer size={1} />
            <IonRow>
              <IonCol size="5" className="self-centered text-start">
                <SmallText tag="strong">{__('Paid Amount')}:</SmallText>
              </IonCol>
              <IonCol style={{ textAlign: 'end' }} className="self-centered text-start">
                <SmallText>{Basket.formatPrice(billStatus.table?.amount_paid / 100)}</SmallText>
              </IonCol>
            </IonRow>
            {/* <AmountPaid order={billStatus.table} cards={cards} /> */}
          </div>
        </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  const { restaurants, tableData } = store.restaurants;
  let lastOrder = null;
  const orderHistory = orders.orderHistory;
  if (orderHistory && orderHistory.length > 0) {
    lastOrder = orderHistory[0];
  }

  return {
    lastOrder,
    cards: orders.cards || [],
    billStatus: orders.billStatus || {},
    restaurants: restaurants || [],
    tableData: tableData,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(BillCompleted)));
