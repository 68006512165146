import { Component } from 'react';

export class Redirect extends Component {
  constructor(props) {
    super();
    this.state = { ...props };
  }
  componentWillMount() {
    window.open(this.state.route.loc, '_system', 'location=yes');
  }
  render() {
    window.history.back();
    return false;
  }
}

export default Redirect;
