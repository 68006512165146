import React from 'react';
import {
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonNote,
  IonButton
} from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { Title, SmallText, Spacer, Sectiontitle } from '../../components/common';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import { forwardTo } from '../../lib/utils';
import DrawPayButton from '../../components/drawPayButton';
import CheckoutPay from './CheckoutPay';
import { Capacitor } from '@capacitor/core';
import { getConfig } from '../../appConfig';
import './index.css';

const isWeb = () => Capacitor.getPlatform() === 'web';
class Checkout extends React.Component {
  state = {
    selectedCard: null,
  };

  componentDidMount() {
    const { profile } = this.props;
    const { google } = window;
    if (getConfig().payment === 'judopay') {
      let judoPayCardsLen = profile.judo_pay_cards.length - 1;
      this.changeSelectedPaymentCard(judoPayCardsLen);
    }
    if (profile.cardToken) {
      this.changeSelectedPaymentCard(profile.cardToken);
    }
    if (google) {
      window.googlePayClient = new google.payments.api.PaymentsClient({
        environment: 'PRODUCTION',
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile.cardToken !== this.props.profile.cardToken) {
      this.changeSelectedPaymentCard(this.props.profile.cardToken);
    }
  }

  changeSelectedPaymentCard = (cardId) =>
    this.setState({ selectedCard: cardId }, () => {
      Basket.changeSelectedCard(cardId);
    });

  render() {
    const { __, cards, clientSecret, dispatch, location, profile } = this.props;
    return (
      <Loading transparent>
        <Layout color="white" headerTitle={__('Checkout')}>
          <div className="flex-row-wrapper absolute-content">
            <Title>{__('Checkout')}</Title>
            {isWeb() ? (
              <>
                <SmallText color="gray">{__('Use saved payment card')}</SmallText>
                <Spacer size={1} />
                <div className="scrollable-y">
                  <IonList lines="full">
                    <IonRadioGroup
                      onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                      value={this.state.selectedCard}
                    >
                      {cards &&
                        cards.map((card) => {
                          const { id, last4, brand, exp_month, exp_year, name } = card;

                          return (
                            <IonItem key={id}>
                              <div tabIndex="-1"></div>
                              <IonRadio
                                color="secondary"
                                slot="start"
                                value={id}
                                onIonSelect={() => {
                                  this.setState({ selectedCard: id }, () => {
                                    Basket.changeSelectedCard(id);
                                  });
                                }}
                              />
                              <IonLabel className="ion-text-wrap">
                                <Sectiontitle className="single-item">{name}</Sectiontitle>
                                <Sectiontitle className="no-margin">
                                  **** **** **** {last4}
                                </Sectiontitle>
                                <IonNote>
                                  {__(brand)} - {exp_month}/{exp_year}
                                </IonNote>
                              </IonLabel>
                            </IonItem>
                          );
                        })}
                    </IonRadioGroup>
                  </IonList>
                  <IonButton
                    fill="clear"
                    color="primary"
                    className="link underlined"
                    onClick={() => forwardTo('/card-add', { addCardFromCheckout: true })}
                  >
                    {__('Add New Payment Card')}
                  </IonButton>
                </div>
                <div className="flex-min">
                  <CheckoutPay
                    clientSecret={clientSecret}
                    dispatch={dispatch}
                    __={__}
                    location={location}
                    profile={profile}
                  />
                  {getConfig()?.prebuildPayment?.showOnNative ? (
										<IonButton
                      expand="block"
                      className="round-button"
											onClick={() => {
												Basket.createStripeOrder(getConfig().prebuildPayment?.label);
											}}
										>
											{__(getConfig().prebuildPayment?.label)}
										</IonButton>
									) : null}
                  <IonButton
                    disabled={cards.length === 0}
                    onClick={() => Basket.createOrder()}
                    expand="block"
                    className="round-button"
                  >
                    {__('Pay')} {Basket._getTotal()}
                  </IonButton>
                </div>
              </>
            ) : null}
            {!isWeb() ? (
              <>
                <div>
                </div>
                <div className="flex-min">
									{getConfig()?.prebuildPayment?.showOnNative ? (
										<IonButton
                      expand="block"
                      className="round-button"
											onClick={() => {
												Basket.createStripeOrder(getConfig().prebuildPayment?.label);
											}}
										>
											{__(getConfig().prebuildPayment?.label)}
										</IonButton>
									) : null}
									{getConfig().nativePayment?.isVisible ? (
										<IonButton
                      expand="block"
                      className="round-button"
											onClick={() => {
												Basket.createOrder('NativePayment');
											}}
										>
											{__(getConfig().nativePayment?.label)}
										</IonButton>
									) : null}
                </div>
              </>
            ) : null
            }
            <div className="flex-min">
              <div className="centered">
                <IonButton
                  color="primary"
                  fill="clear"
                  expand="block"
                  className="link checkout-cancel"
                  onClick={() => forwardTo('/order-summary')}
                >
                  {__('Cancel')}
                </IonButton>
              </div>
            </div>
          </div>
        </Layout>
      </Loading >
    );
  }
}

const mapStateToProps = (store) => {
  return {
    cards: store.orders.cards || [],
    clientSecret: store.orders.clientSecret,
    profile: store.profile.profile || {},
  };
};

export default connect(mapStateToProps)(withTranslation(Checkout));
