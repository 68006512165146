import React from 'react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, SmallText, Spacer } from '../../components/common';
import Box, { BoxHeader } from '../../components/box';
import OrderContent from '../../components/orderContent';
import { createNewBasketInstance } from '../../lib/basket';
import { forwardTo, isDefined } from '../../lib/utils';
import { connect } from 'react-redux';
import AmountPaid from '../../components/amountPaid';
import './index.css';

class HistoryDetails extends React.Component {
  backHandler = () => {
    forwardTo('/dashboard');
    forwardTo('/history', { tab: 'order' });
  };

  formatPaymentString = (str) => {
    if (/\d/.test(str)) {
      return str.split(' ').splice(2).join(' ');
    }
    return str;
  };

  drawContentTitle = (__, deliveryOption, id, orderType) => {
    if (orderType === 'Outpost Drop-Off') {
      return (
        <>
          {__('Drop Point')} {__('Order')} #{id}
        </>
      );
    } else if (orderType === 'Delivery') {
      return (
        <>
          <strong>
            {__('Delivery')} {__('Order')} #{id}
          </strong>
        </>
      );
    } else {
      return (
        <>
          {__(orderType)} {__('Order')} #{id}
        </>
      );
    }
  };

  render() {
    const { __, location, cards } = this.props;
    const order = location.state.order;
    const basketInstance = createNewBasketInstance();
    basketInstance.recreateOrder(order);
    const orderType = basketInstance.getOrderType();
    let orderTable =
      orderType === 'Table' && isDefined(order.table_name) && order.table_name !== ''
        ? order.table_name
        : '';
    let orderTypePrefix = orderType !== 'Collection' ? 'Order' : 'Collection';
    return (
      <Loading>
        <Layout headerTitle={__('History')} backHandler={this.backHandler}>
          <div className="absolute-content history-details">
            <div className="scrollable-y">
              <div className="order-status">{order.status ? __(order.status) : ''}</div>
              <Spacer size={2} />
              <Title>{__('History')}</Title>
              <SmallText color="primary">
                {__(basketInstance.getOrderType())} {__('Order')} #{order.id}
              </SmallText>
              {/* <Title>{ this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle) }</Title> */}
              <Spacer size={3} />
              <Box>
                <BoxHeader>
                  {/* <p className="light-text">
										<SmallText tag="strong">{ __('Order Location') }:</SmallText> <SmallText>{ order.restaurant_name }</SmallText><br />
										<SmallText tag="strong">{ __('Order Time') }:</SmallText> <SmallText>{ basketInstance.formatOrderTime() }</SmallText><br />
										<SmallText tag="strong">{ __('Payment Method') }:</SmallText> <SmallText>{ this.formatPaymentString(basketInstance.formatPaymentMethod(cards, __)) }</SmallText>
									</p> */}
                  {/* { this.drawContent(__, order, orderType, basketInstance, orderCompletePage) } */}
                  <p className="light-text">
                    <SmallText tag="strong">
                      {__(orderTypePrefix) + ' '} {__('Location')}:
                    </SmallText>{' '}
                    <SmallText>{order.restaurant_name}</SmallText>
                    <br />
                    {basketInstance.getOrderType() === 'Delivery' ? (
                      <>
                        <SmallText tag="strong">{__('Delivery Address')}:</SmallText>{' '}
                        <SmallText>
                          {order.delivery_address.addressLine1}, {order.delivery_address.place},{' '}
                          {order.delivery_address.postalCode}
                        </SmallText>
                        <br />
                      </>
                    ) : null}
                    <SmallText tag="strong">
                      {__(orderTypePrefix) + ' '} {__('Time')}:
                    </SmallText>{' '}
                    <SmallText>{basketInstance.formatOrderTime().replace(/\//g, 'at')}</SmallText>
                    <br />
                    {orderTable !== '' ? (
                      <>
                        <SmallText tag="strong">{__('Table number')}:</SmallText>{' '}
                        <SmallText>{orderTable}</SmallText>
                        <br />
                      </>
                    ) : null}
                    {order && order.status === 'REFUNDED' ? (
                      <>
                        <SmallText tag="strong">{__('Order Status')}:</SmallText>{' '}
                        <SmallText>{'Refunded'}</SmallText>
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                    {order && order.amount_paid !== 0 ? (
                      <>
                        <SmallText tag="strong">{__('Payment Method')}:</SmallText>{' '}
                        <SmallText>
                          {this.formatPaymentString(basketInstance.formatPaymentMethod(cards, __))}
                        </SmallText>
                      </>
                    ) : null}
                  </p>
                </BoxHeader>
                <OrderContent basketInstance={basketInstance} type="orderHistory" />
                <AmountPaid order={order} cards={cards} />
              </Box>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  return {
    cards: orders.cards || [],
  };
};

export default connect(mapStateToProps)(withTranslation(HistoryDetails));
