import {
  SET_RESTAURANT_PROP,
  BEFORE_SHOW_TIME_PICKER,
  BEFORE_CLOSE_TIME_PICKER,
  PASSED_ORDER,
  SET_TABLE_NUMBERS,
  SET_TABLE_DATA,
} from './constants';

let initialState = {
  restaurants: [],
  rewards: [],
  ikentooMenu: {},
  ikentooMenusForLocation: [],
  isShowTimePicker: false,
  itemAllergens: [],
  isPassedOrder: false,
  tableNumbers: {},
  selectedTable: {},
  tableData: {},
  selectedRestaurant: {},
  selectedTable: 0,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_RESTAURANT_PROP:
      return { ...state, [action.key]: action.value };
    case BEFORE_SHOW_TIME_PICKER:
      return { ...state, isShowTimePicker: true };
    case BEFORE_CLOSE_TIME_PICKER:
      return { ...state, isShowTimePicker: false };
    case SET_TABLE_NUMBERS:
        return { ...state, [action.key]: action.value };
    case SET_TABLE_DATA:
      return { ...state, [action.key]: action.value };
    case PASSED_ORDER:
      return { ...state, isPassedOrder: action.isPassedOrder };
    default:
      return state;
  }
}

export default reducer;
