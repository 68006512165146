import React, { Component } from 'react';
import { IonItem, IonLabel, IonButton, IonList } from '@ionic/react';
import { isEmptyArray, forwardTo } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import './index.css';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { NormalText } from '../common';

class OrderContent extends Component {
  state = {
    tableItems: [],
  };

  componentDidMount() {
    // const { tableItems } = this.props
    // this.setState({ tableItems })
  }

  render() {
    const { __ } = this.props;

    return (
      <>
        <h2 className="title">{__('Checkout')}</h2>
        <div className="pay-table">
          <IonItem lines="none">
            <IonLabel>
              {__(
                `Pay the bill for Table ${Basket.getTableNumber()} at ${Basket.getRestauranName()}`,
              )}
            </IonLabel>
          </IonItem>
          <IonList>
            {Basket.table_items.map((item, index) => {
              return (
                item.quantity > 0 && (
                  <div key={index}>
                    <IonItem lines="none" key={index}>
                      <div className="price-item">
                        <IonLabel className="item-name">
                          {item.quantity} x {__(item.itemName)}
                        </IonLabel>
                        <IonLabel className="item-price">
                          {__(item.unitAmount * item.quantity).toFixed(2)}
                        </IonLabel>
                      </div>
                    </IonItem>
                    <div>
                      {!isEmptyArray(item.subLineItems)
                        ? item.subLineItems.map((subitem) => {
                            return (
                              <div className="subitem" key={subitem.id}>
                                <IonLabel>
                                  {subitem.quantity} x {subitem.itemName}
                                </IonLabel>
                                <IonLabel>
                                  {(subitem.unitAmount * subitem.quantity).toFixed(2)}
                                </IonLabel>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                )
              );
            })}
            <div className="line" style={{ backgroundColor: '#284734' }} />
            <IonItem lines="none">
              <div className="price-item">
                <NormalText>{__('Total Bill')}</NormalText>
                <NormalText className="item-price">{Basket.getTotalBill()}</NormalText>
              </div>
            </IonItem>
            <IonItem lines="none">
              <div className="price-item">
                <NormalText>{__('Bill Payment')}</NormalText>
                <NormalText className="item-price">{Basket.getTableAmountToPay()}</NormalText>
              </div>
            </IonItem>
          </IonList>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    isRemoveVoucherModalOpen: store.profile.isRemoveVoucherModalOpen,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderContent)));
