import React from 'react';
import { connect } from 'react-redux';
import {
  IonItem,
  IonLabel,
  IonButton,
  IonList,
  IonSpinner,
  IonText,
  IonCheckbox,
  IonCol,
  IonRow,
  IonGrid,
  IonAlert,
} from '@ionic/react';
import Basket from '../../lib/basket';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { isEmptyArray, forwardTo, isEmptyObject } from '../../lib/utils';
import { NormalText, Spacer, Title } from '../../components/common';
import Loading from '../../components/spinner';
import { SELECT_TABLE_ITEM, SET_TABLE_PAY_METHOD } from '../../store/constants';
import { selectTableItems } from '../../store/actions';
import './index.css';

const { setTablePayMethod } = Basket;

const LabelStyle = {
  fontSize: 14,
  fontWeight: 700,
  color: '#284734',
};

class PayByItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsPrice: 0,
      paidItems: [],
      isSetPayMethodModalOpen: false,
    };
  }
  componentDidMount() {
    if (Basket.bill_data.pay_method !== '3') {
      forwardTo('/table-bill-pay');
    }
  }
  setTablePayMethod = () => {
    this.props.dispatch({
      type: SET_TABLE_PAY_METHOD,
      data: {
        pay_method: 2,
        table_bill_id: Basket.bill_data.id,
      },
    });
  };
  handleInputChange = (e, item) => {
    const paidItems = Basket.paid_items;
    let updatedItems = [];
    if (e.target.checked) {
      updatedItems.push(...paidItems, item);
      this.props.dispatch(
        selectTableItems({
          tableNumber: Basket.getPayTableNumber(),
          businessLocationId: Basket.getRestaurant().pos_location_id,
          selected_item: {
            user_id: this.props.profile.id,
            item_id: item.id,
            selected: true,
          },
        }),
      );
    } else {
      const removedItem = paidItems.filter((el) => el.id !== item.id);
      updatedItems = removedItem;
      this.props.dispatch(
        selectTableItems({
          tableNumber: Basket.getPayTableNumber(),
          businessLocationId: Basket.getRestaurant().pos_location_id,
          selected_item: {
            user_id: this.props.profile.id,
            item_id: item.id,
            selected: false,
          },
        }),
      );
    }
  };

  payTable = () => {
    Basket.setTablePaymentAmount(Basket.table_items_price);
    forwardTo('/bill-summary');
  };

  render() {
    const { __ } = this.props;
    const tableItems = Basket.getTableItems();
    const deliveryOption = Basket.getDeliveryOption();
    return (
      <Loading transparent>
        <Layout
          headerTitle={__(deliveryOption ? deliveryOption.label : '')}
          hideSecondToolbar={true}
        >
          <div className="absolute-content  scrollable-y flex-row-wrapper">
            <div className="">
              <div className="flex-min">
                <Title>{__('Pay by item')}</Title>
                <NormalText>{__(`Table ${Basket.getTableNumber()}`)}</NormalText>
                <Spacer size={2} />
              </div>
              {tableItems.map((item, index) => {
                const isChecked = !!Basket.bill_data.selected_items.find(
                  (el) => el.item_id == item.id && el.user_id == this.props.profile.id,
                );
                const isDisabled =
                  !!Basket.bill_data.paid_items.find((el) => el.id == item.id) ||
                  !!Basket.bill_data.selected_items.find(
                    (el) => el.item_id == item.id && el.user_id !== this.props.profile.id,
                  );
                return (
                  item.quantity > 0 && (
                    <div key={index}>
                      <IonItem lines="none" key={index} className="table-item-wrapper">
                        {parseFloat(item.unitAmount) > 0 && (
                          <IonCheckbox
                            disabled={isDisabled}
                            color="primary"
                            slot="start"
                            onIonChange={(event) => this.handleInputChange(event, item)}
                            value={item.unitAmount * item.quantity}
                            checked={isChecked}
                          />
                        )}
                        <IonGrid style={{ width: '100%' }}>
                          <IonRow className={isDisabled ? 'disabled' : ''}>
                            <IonCol className={parseFloat(item.unitAmount) > 0 ? '' : 'empty-item'}>
                              {item.quantity} x {__(item.itemName)}
                            </IonCol>
                            <IonCol className="righted">
                              {Basket.formatPrice(item.unitAmount * item.quantity, true)}
                            </IonCol>
                          </IonRow>
                          {!isEmptyArray(item.subLineItems)
                            ? item.subLineItems.map((subitem) => {
                                return (
                                  <IonRow
                                    className={
                                      isDisabled
                                        ? 'disabled subitem pay-items'
                                        : 'subitem pay-items'
                                    }
                                    key={subitem.id}
                                  >
                                    <IonCol>
                                      {subitem.quantity} x {subitem.itemName}
                                    </IonCol>
                                    <IonCol>
                                      {(subitem.unitAmount * subitem.quantity).toFixed(2)}
                                    </IonCol>
                                  </IonRow>
                                );
                              })
                            : null}
                        </IonGrid>
                      </IonItem>
                    </div>
                  )
                );
              })}
              <IonGrid>
                <IonRow className="price-item">
                  <IonCol style={LabelStyle}>{__('Total To Pay')}</IonCol>
                  <IonCol className="righted">
                    {Basket.formatPrice(Basket.getTableItemPrice(this.props.profile.id), true)}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <Spacer />
            <IonButton
              onClick={() => this.setState({ isSetPayMethodModalOpen: true })}
              expand="block"
              fill="clear"
              className="underlined ion-margin-top"
            >
              {__('Or pay another amount')}
            </IonButton>
            <Spacer />
            <div className="flex-min">
              <IonButton
                disabled={Basket.getTableItemPrice(this.props.profile.id) == 0}
                expand="block"
                color="secondary"
                className="round-button okx-font-secondary"
                onClick={() => this.payTable()}
              >
                {__(
                  `Pay ${Basket.formatPrice(
                    Basket.getTableItemPrice(this.props.profile.id),
                    true,
                  )}`,
                )}
              </IonButton>
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={this.state.isSetPayMethodModalOpen}
          onDidDismiss={() => this.setState({ isSetPayMethodModalOpen: false })}
          header={__('Are you sure you want to set this?')}
          // message={__(this.getRestaurantDataMessage(__))}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.setState({ isSetPayMethodModalOpen: false }),
            },
            {
              text: __('Confirm'),
              role: 'submit',
              cssClass: 'secondary',
              handler: () => this.setTablePayMethod(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const { tableData } = store.restaurants;
  const { auth } = store.profile;

  return {
    tableData: tableData,
    profile: store.profile.profile,
    cards: store.orders.cards || [],
    auth,
  };
};

export default connect(mapStateToProps)(withTranslation(PayByItems));
