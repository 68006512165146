import React from 'react';
import { withRouter } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonButtons,
  IonButton,
  IonIcon,
  IonBadge,
} from '@ionic/react';
import { scan } from 'ionicons/icons';
import basketIcon from '../../assets/images/basket-icon.png';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import { forwardTo } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import BackButton from '../backButton';
import './index.css';
import Basket from '../../lib/basket';
import { SmallText } from '../common';

// const qrCode = require('../../assets/images/qr-icon.svg')
// const menu = require('../../assets/images/menu-icon.svg')

const StaticHeader = ({ __, history, backHandler }) => {
  const currentPath = history.location.pathname;
  const authPages = getConfig().general.authRoutes.indexOf(currentPath) !== -1;
  const hideStaticHeader = getConfig().theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1;
  const isDeliveryOptions = currentPath === '/delivery-options';
  const routeClassName = 'route-' + currentPath.replace('/', '');
  const colorAttr = routeClassName === 'route-dashboard' ? { color: 'primary' } : { color: 'dark' };
  const orderType = Basket.getOrderType();
  const pagesWithoutBackButton =
    getConfig().general.routesWithoutBackButton.indexOf(currentPath) !== -1;

  return (
    <>
      {hideStaticHeader ? null : getConfig().theme.showHeaderOnAuthRoutes || !authPages ? (
        <IonHeader
          className={
            isDeliveryOptions ? `dark-headerstatic ${routeClassName}` : `static ${routeClassName}`
          }
        >
          <IonToolbar className="primary-toolbar">
            {
              <IonButtons slot="start">
                {!authPages || pagesWithoutBackButton ? (
                  <IonMenuButton {...colorAttr} />
                ) : (
                  <BackButton backHandler={backHandler} />
                )}
              </IonButtons>
            }
            <IonButtons color="primary">
              <IonButton
                className="image-button"
                color="primary-shade"
                onClick={() => {
                  forwardTo('/dashboard');
                }}
              />
            </IonButtons>
            <IonButtons slot="end">
              {Basket.itemsCount() > 0 ? (
                <IonButton
                  className="basket-button"
                  color="primary"
                  onClick={() => {
                    if (orderType !== 'Table') {
                      forwardTo('/order-summary');
                    } else {
                      forwardTo('/pay-table');
                    }
                  }}
                  size={24}
                >
                  <div>
                    <div className="basket-icon-wrapper">
                      <IonBadge
                        slot="end"
                        {...colorAttr}
                        className={Basket.itemsCount() >= 10 ? 'badge' : 'badge-small'}
                      >
                        {Basket.getItemsAndQuantitySum()}
                      </IonBadge>
                      <img className="basket-icon-image" src={basketIcon} alt="" />
                    </div>
                    <div>
                      <SmallText color="gray">{Basket._getTotal()}</SmallText>
                    </div>
                  </div>
                </IonButton>
              ) : !authPages ? (
                <IonButton
                  {...colorAttr}
                  button
                  color="primary"
                  clear
                  onClick={() => forwardTo('/loyalty', { tab: 'scan' })}
                >
                  <IonIcon slot="icon-only" icon={scan} />
                </IonButton>
              ) : null}
            </IonButtons>
          </IonToolbar>
          <IonToolbar color="transparent" />
        </IonHeader>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { orders } = store;
  return {
    basketUpdated: orders.basketUpdated,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)));
