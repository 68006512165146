import React from 'react';
import { connect } from 'react-redux';
import { IonButton, IonAlert } from '@ionic/react';
import Basket from '../../lib/basket';
import { NormalText, SmallText, Spacer, Title } from '../../components/common';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import Loading from '../../components/spinner';
import { SET_TABLE_PAY_METHOD } from '../../store/constants';

class TableBillOpened extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSetPayMethodModalOpen: false,
    };
  }

  setTablePayMethod = () => {
    this.props.dispatch({
      type: SET_TABLE_PAY_METHOD,
      data: {
        pay_method: 2,
        table_bill_id: Basket.bill_data.id,
      },
    });
  };
  render() {
    const { __ } = this.props;
    return (
      <Loading transparent>
        <Layout noPadding color="transparent">
          <div className="scrollable-y absolute-content">
            <Title>{__('Table Information')}</Title>
            <Spacer />

            <NormalText>{__('Another user is paying for this table.')}</NormalText>
            <Spacer />
            <IonButton
              expand="block"
              onClick={() => this.setState({ isSetPayMethodModalOpen: true })}
              className="round-button "
              color="secondary"
            >
              {__('I want to pay instead')}
            </IonButton>
          </div>
        </Layout>
        <IonAlert
          isOpen={this.state.isSetPayMethodModalOpen}
          onDidDismiss={() => this.setState({ isSetPayMethodModalOpen: false })}
          header={__('Are you sure you want to set this?')}
          // message={__(this.getRestaurantDataMessage(__))}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.setState({ isSetPayMethodModalOpen: false }),
            },
            {
              text: __('Confirm'),
              role: 'submit',
              cssClass: 'secondary',
              handler: () => this.setTablePayMethod(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const { tableData } = store.restaurants;
  const { auth } = store.profile;

  return {
    tableData: tableData,
    profile: store.profile.profile,
    cards: store.orders.cards || [],
    auth,
  };
};

export default connect(mapStateToProps)(withTranslation(TableBillOpened));
