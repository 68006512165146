import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonItem, IonCard, IonCardContent, IonCardHeader, IonButton, IonAlert } from '@ionic/react';
import Basket from '../../lib/basket';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import { getRestaurants, getRestaurantTable } from '../../store/restaurants/actions';
import { showToast } from '../../store/actions';
import { forwardTo, isEmptyArray, isEmptyObject } from '../../lib/utils';
import { Title } from '../../components/common';
import './index.css';
import Loading from '../../components/spinner';
import { INIT_FIREBASE_LISTENER, SET_TABLE_DATA } from '../../store/constants';

const { SelectOption } = Mobiscroll;

class OrderAtTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurant: null,
      tables: [],
      table: null,
      restaurantValue: null,
      tableValue: null,
      isSubmitRestaurantAlertOpen: false,
    };
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);

    const { restaurants, dispatch } = this.props;
    !isEmptyArray(restaurants) && dispatch(getRestaurants());
    if (searchParams.get('bl_id')) {
      const selectedRestaurant = this.props.restaurants.find(
        (restaurant) => restaurant.business_location_id == searchParams.get('bl_id'),
      );
      this.setState({
        restaurant: selectedRestaurant,
        restaurantValue: selectedRestaurant.business_location_id,
        tables: this.props.tableNumbers[selectedRestaurant.pos_location_id],
      });
      if (searchParams.get('t_id')) {
        this.setState({
          tableValue: searchParams.get('t_id'),
        });
      }
    }
    Basket.reset();
    Basket.setOrderType('bill-pay');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.restaurant !== this.state.restaurant) {
      if (!isEmptyObject(this.props.tableNumbers)) {
        this.setState({ tables: this.props.tableNumbers[this.state.restaurant.pos_location_id] });
      }
    }
  }

  handleRestaurantSelection = (e, a) => {
    if (a.getVal() !== null) {
      this.setState({
        restaurant: this.props.restaurants.find((el) => el.business_location_id == a.getVal()),
        restaurantValue: a.getVal(),
        tableValue: null,
      });
    }
  };

  formatMobiSelect = (flag) => {
    const { __ } = this.props;
    const { tables } = this.state;
    let listForSelect = [];
    // Format for restaurants selection
    if (flag === 'restaurant') {
      if (!isEmptyArray(this.props.restaurants)) {
        this.props.restaurants.map((restaurant, index) => {
          listForSelect.push({ text: restaurant.name, value: restaurant.business_location_id });
        });
      }
      return [{ text: __('Select Restaurants'), value: null }, ...listForSelect];
    } else {
      if (!isEmptyArray(tables)) {
        tables.map((table, index) => {
          listForSelect.push({ text: table.description, value: table.number });
        });
      }
      return [{ text: __('Select table'), value: null }, ...listForSelect];
    }
  };

  onClickNext = () => {
    const { tableValue, restaurant } = this.state;
    const { dispatch } = this.props;
    if (!tableValue) {
      dispatch(showToast('Choose table', 'danger'));
      return;
    }
    Basket.setRestaurant(restaurant);
    Basket.setTableNumber(tableValue);
    this.setState({ isSubmitRestaurantAlertOpen: true });
  };
  forwardToBillPay = async () => {
    const { tableValue, restaurant } = this.state;
    const { dispatch } = this.props;
    dispatch({ type: SET_TABLE_DATA, key: 'tableData', value: {} });
    dispatch(getRestaurantTable(restaurant, tableValue));
  };
  getRestaurantDataMessage = (__) => {
    return ` <p><b>${__('Table')}:</b> ${Basket.getTableNumber()}</p> \n <p><b>${__(
      'Location',
    )}:</b> ${Basket.getRestauranName()}</p> `;
  };
  render() {
    const { __ } = this.props;
    const { restaurantValue, tables, tableValue } = this.state;
    const deliveryOption = Basket.getDeliveryOption();
    return (
      <Loading>
        <Layout
          headerTitle={__(deliveryOption ? deliveryOption.label : '')}
          noPadding
          color="transparent"
        >
          <div className="absolute-content order-at-table-bg"></div>
          <IonCard className="table-options-card">
            <IonCardContent className="card-body">
              <Title>{__('Input Table Details')}</Title>

              <IonItem className="text-label" lines="none">
                {__('Restaurant')}
              </IonItem>
              <SelectOption
                display="center"
                onSet={(e, a) => this.handleRestaurantSelection(e, a)}
                data={this.formatMobiSelect('restaurant')}
                label="Restaurants"
                value={restaurantValue}
                inputStyle="box"
                placeholder={__('Select Restaurants')}
                setText={__('OK')}
                cancelText={__('Cancel')}
                disabled={this.props.restaurants.length > 0 ? false : true}
              />
              <IonItem className="text-label" lines="none">
                {__('Table')}
              </IonItem>
              <SelectOption
                display="center"
                onSet={(e, a) => {
                  this.setState({ tableValue: a.getVal() });
                }}
                data={this.formatMobiSelect('table')}
                label="Table"
                value={tableValue}
                inputStyle="box"
                placeholder={__('Select table number.')}
                setText={__('OK')}
                cancelText={__('Cancel')}
                disabled={tables.length > 0 ? false : true}
              />
              <IonButton
                disabled={this.state.tableValue == null}
                color="secondary"
                className="next-button"
                onClick={this.onClickNext}
              >
                {__('Next')}
              </IonButton>
            </IonCardContent>
          </IonCard>
          <IonAlert
            isOpen={this.state.isSubmitRestaurantAlertOpen}
            onDidDismiss={() => this.setState({ isSubmitRestaurantAlertOpen: false })}
            header={__('Please check your table')}
            message={__(this.getRestaurantDataMessage(__))}
            buttons={[
              {
                text: __('Cancel'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => this.setState({ isSubmitRestaurantAlertOpen: false }),
              },
              {
                text: __('Confirm'),
                role: 'submit',
                cssClass: 'secondary',
                handler: () => this.forwardToBillPay(),
              },
            ]}
          />
        </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const { restaurants, tableNumbers } = store.restaurants;

  return {
    restaurants: restaurants || [],
    tableNumbers: tableNumbers || {},
  };
};

export default connect(mapStateToProps)(withTranslation(OrderAtTable));
