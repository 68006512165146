import React from 'react';
import { IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import { withRouter } from 'react-router';
import { SmallText } from '../../components/common';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import { isDefined } from '../../lib/utils';
import NoDataSecond from '../../components/noDataSecond';

const doRefresh = (event, getTransactionHistory) => {
  setTimeout(() => {
    event.detail.complete();
    getTransactionHistory();
  }, 1000);
};

const HistoryTab = withRouter(
  ({ __, transactionHistory, getTransactionHistory, history, ...rest }) => {
    const type = rest.type || 'order';

    const handleRowClick = (item) => {
      if (type === 'order') {
        history.push('./history-details', { order: item });
      } else {
        // history.push('./history-details')
      }
    };

    const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];

    return (
      <>
        <IonRefresher slot="fixed" onIonRefresh={(e) => doRefresh(e, getTransactionHistory)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className="history-content">
          {(transactionHistory || []).length === 0 ? (
            <NoDataSecond label="Your order History will appear here" />
          ) : (
            <IonGrid>
              {transactionHistory.map((i, index) => {
                const { stamp_power, transaction_date, location_name, label, item } = i;
                let status = item && item.status ? item.status : '';
                let orderLabel =
                  Basket.getOrderType(i.item) === 'delivery'
                    ? 'charter-delivery'
                    : Basket.getOrderType(i.item);
                let option = (delivery || []).find((d) => d.id === orderLabel);
                let orderId = i && i.item ? ' #' + i.item.id : '';
                return status === 'CREATED' ||
                  status === 'created' ||
                  status === 'new' ||
                  status === 'NEW' ? null : (
                  <IonRow onClick={() => handleRowClick(item)} key={index} className="history-item">
                    <IonCol>
                      <SmallText color="gray">
                        {Basket.getDate(transaction_date).format('ddd DD MMMM YYYY / LT')}
                      </SmallText>
                      <h2 className="uppercase">
                        {type === 'order' ? (option ? __(option.label) + orderId : '') : __(label)}
                      </h2>
                      <SmallText color="gray">{location_name}</SmallText>
                    </IonCol>
                    {type === 'order' ? (
                      <IonCol style={{ flex: '0 1 0', alignSelf: 'center' }}>
                        <div className="order-button-wrapper">
                          <div className="order-button bordered-primary">
                            {__(item && item.status ? item.status : '')}
                            {/* <IonIcon color="primary" slot="end" icon={ arrowForward }/> */}
                          </div>
                        </div>
                      </IonCol>
                    ) : (
                      <IonCol style={{ alignSelf: 'center' }}>
                        <p className={stamp_power < 0 ? 'redeem' : ''}>
                          {/* <img src={ stamp_power < 0 ? redeemedArrow : earnedArrow } alt="point-arrows"/> */}
                          {stamp_power < 0 ? '' : ''}
                          {stamp_power} {__('points')}
                        </p>
                      </IonCol>
                    )}
                  </IonRow>
                );
              })}
            </IonGrid>
          )}
        </div>
      </>
    );
  },
);

export default withTranslation(HistoryTab);
