import React, { Component } from 'react';
import { withTranslation } from '../../lib/translate.js';
import noDataImage from '../../assets/images/icon.png';
import { Spacer } from '../../components/common';
import './index.css';

class NoDataSecond extends Component {
  render() {
    const { __, label, className, style } = this.props;
    return (
      <div className="no-data-second-wrapper">
        <div className="no-data-second-topic">
          <img src={noDataImage} alt="no-data-image" />
        </div>
        <Spacer size={1} />
        <p className={className || ''} style={{ textAlign: 'center', ...(style || {}) }}>
          {__(label) || 'No data'}
        </p>
      </div>
    );
  }
}

export default withTranslation(NoDataSecond);
