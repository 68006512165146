import React, { useState, useEffect, useCallback } from 'react';
import { IonIcon, IonInput, IonButton, IonLabel } from '@ionic/react';
import './index.css';
import { isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import check from '../../assets/images/Deli_Check.svg';
import wrong from '../../assets/images/Deli_Wrong.svg';

const { DatePicker } = Mobiscroll;

const ValidateInput = (props) => {
  const [value, setValue] = useState(props.value || '');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.name) {
      const valid = props.validatedData[props.name];
      setShow(valid);
    }
  }, [props.name, props.validatedData]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.name) {
      const valid = props.validatedData[props.name];
      setShow(valid);
    }
  }, [props.name, props.validatedData]);

  const togglePass = useCallback(() => {
    const { type } = props;
    if (type && type === 'email') {
      const isValidEmail = isDefined(value) && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
      setShow(isValidEmail);
    } else {
      const isValid = isDefined(value) && (value !== '' || props.validatedData[props.name]);
      setShow(isValid);
    }
  }, [value, props.type, props.validatedData, props.name]);

  const handlePeriodicalSaga = (flag) => {
    const { dispatch } = props;
    dispatch({ type: 'SET_COMMON_PROP', key: 'emitterFlag', value: flag });
  };

  const onChange = (e) => {
    setValue(e.target.value);
    togglePass();

    if (props.onIonChange) {
      props.onIonChange(e);
    }
  };

  const { __, label, type, ...rest } = props;
  const additionClass = type === 'date'
    ? 'okx-validate-input-holder--date'
    : type === 'email'
      ? 'okx-validate-input-holder--email'
      : '';

  return (
    <div className="okx-validate-input-wrapper">
      <div className={`okx-validate-input-holder ${additionClass}`}>
        {label ? <IonLabel position="floating">{__(label)}</IonLabel> : null}
        {type === 'date' && (
          <>
            <label className="date-picker-modal-label" htmlFor="demo-non-form">
              {__('Date of birth')}
            </label>
            <DatePicker
              className="data-picker-input"
              display="bottom"
              setText="Done"
              onSet={(e, a) => onChange({ target: { value: a.element.value } })}
              {...rest}
              value={value}
            />
          </>
        )}
        {type === 'text' && (
          <IonInput {...rest} value={value} onIonInput={onChange}></IonInput>
        )}
        {type === 'email' && (
          <IonInput readOnly {...rest} value={value} onIonInput={onChange}></IonInput>
        )}
        {type === 'tel' && (
          <IonInput {...rest} value={value} onIonInput={onChange}></IonInput>
        )}
        {type === 'email_verified' && <p>{__('Email Address Validated')}</p>}
      </div>
      <IonButton
        fill="clear"
        size="small"
        onTouchStart={togglePass}
        onTouchEnd={togglePass}
      >
        <IonIcon slot="icon-only" icon={show ? check : wrong} />
      </IonButton>
    </div>
  );
};

export default withTranslation(ValidateInput);
