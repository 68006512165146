import {
  GET_TRANSACTION_HISTORY,
  IMPORT_BASKET,
  LIST_PAYMENT_CARDS,
  ADD_PAYMENT_CARD,
  REMOVE_PAYMENT_CARD,
  CREATE_ORDER,
  SET_SCROLL_TOP,
  ADD_SCANNED_CARD,
  ADD_PICKUP_POINT,
  SET_PICK_UP_POINT,
  STORE_PICKUP_POINT,
  LOCATION_CODE_CHECK,
  SET_LOCATION_CODE_DATA,
  SET_DELIVERY_OPTION,
  SET_DELIVERY_TIME,
  SET_ORDERS_PROP,
  STORE_ITEM_WEB,
  CHECK_CANCEL_ORDER,
  UPDATE_PAYMENT_INFO,
  GET_NEAREST_LOCATION,
  POSTCODE_CHECK,
  SET_DELIVERY_ADDRESS,
  SET_POSTCODE_DATA,
  STORE_DELIVERY_ADDRESS,
  REMOVE_DELIVERY_ADDRESS,
  ADD_DELIVERY_ADDRESS,
  SEND_TABLE_BILL,
  SET_TABLE_PAY_METHOD,
  SELECT_TABLE_ITEM,
  CREATE_STRIPE_ORDER,
} from './constants';

export const getTransactionHistory = () => ({ type: GET_TRANSACTION_HISTORY });

export const importBasket = () => ({ type: IMPORT_BASKET });

export const getPaymentCards = () => ({ type: LIST_PAYMENT_CARDS });

export const addPaymentCard = (name, options = {}) => ({ type: ADD_PAYMENT_CARD, name, options });

export const removePaymentCard = (cardToken, options = {}) => ({
  type: REMOVE_PAYMENT_CARD,
  cardToken,
  options,
});

export const createOrder = (paymentType = null, paymentWebType, cb) => ({
  type: CREATE_ORDER,
  paymentType,
  paymentWebType,
  cb,
});

export const setScrollTop = (value = 0) => ({ type: SET_SCROLL_TOP, value });

export const payTableBill = (paymentType, paymentWebType, cb) => {
  return {
    type: SEND_TABLE_BILL,
    paymentType,
    paymentWebType,
    cb,
  };
};

export const addScannedCard = (name = '', payment_token = '', options = {}) => ({
  type: ADD_SCANNED_CARD,
  name,
  options,
  payment_token,
});

export const addPickupPoint = (pickUpPoint, code) => ({
  type: ADD_PICKUP_POINT,
  pickUpPoint,
  code,
});

export const setPickUpPoint = (pickUpPoint) => ({ type: SET_PICK_UP_POINT, pickUpPoint });

export const storePickUpPoint = (pickUpPoint) => ({ type: STORE_PICKUP_POINT, pickUpPoint });

export const locationCodeCheck = (locationCode) => ({ type: LOCATION_CODE_CHECK, locationCode });

export const setLocationCodeData = (checkedLocationCodeData) => ({
  type: SET_LOCATION_CODE_DATA,
  checkedLocationCodeData,
});

export const setDeliveryOption = (deliveryOption) => ({
  type: SET_DELIVERY_OPTION,
  deliveryOption,
});

export const setDeliveryTime = (deliveryTime) => ({ type: SET_DELIVERY_TIME, deliveryTime });

export const setOrdersProp = (key, value) => ({ type: SET_ORDERS_PROP, key, value });

export const storeItemWeb = (item, cb = null) => ({ type: STORE_ITEM_WEB, item, cb });

export const updatePaymentInfo = (paymentInfo) => ({ type: UPDATE_PAYMENT_INFO, paymentInfo });

export const checkCancelOrder = (orderId, restaurantId) => ({
  type: CHECK_CANCEL_ORDER,
  orderId,
  restaurantId,
});

export const getNearestLocation = (postcode) => ({ type: GET_NEAREST_LOCATION, postcode });

export const postCodeCheck = (postcode) => ({ type: POSTCODE_CHECK, postcode });

export const setDeliveryAddress = (deliveryAddress) => ({
  type: SET_DELIVERY_ADDRESS,
  deliveryAddress,
});

export const setPostCodeData = (checkedCodeData) => ({ type: SET_POSTCODE_DATA, checkedCodeData });

export const storeDeliveryAddress = (deliveryAddress) => ({
  type: STORE_DELIVERY_ADDRESS,
  deliveryAddress,
});

export const removeDeliveryAddress = (index, order_type) => ({
  type: REMOVE_DELIVERY_ADDRESS,
  index,
  order_type,
});

export const addDeliveryAddress = (deliveryAddress, flag) => ({
  type: ADD_DELIVERY_ADDRESS,
  deliveryAddress,
  flag,
});

export const setTablePayMethod = (data) => ({
  type: SET_TABLE_PAY_METHOD,
  data,
});

export const selectTableItems = (data) => ({
  type: SELECT_TABLE_ITEM,
  data,
});

export const createStripeOrder = (paymentType = null, paymentWebType, cb) => ({
	type: CREATE_STRIPE_ORDER,
	paymentType,
	paymentWebType,
	cb,
});

export const updateStripeOrder = (orderId) => ({
	type: CREATE_STRIPE_ORDER,
	orderId,
});