import React from 'react';
import { connect } from 'react-redux';
import { IonList, IonItem, IonLabel, IonCard, IonCardContent, IonCardHeader } from '@ionic/react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { setDeliveryOption, getSocials } from '../../store/actions';
import { forwardTo, getSingleDeliveryOption, isDefined } from '../../lib/utils';
import Basket from '../../lib/basket';
import { Title } from '../../components/common';
import './index.css';
import { getConfig } from '../../appConfig';

class DeliveryOptions extends React.Component {
  constructor() {
    super();
    this.state = {
      delivery: [],
    };
  }
  setDeliveryOption = (delivery) => {
    this.props.dispatch(setDeliveryOption(delivery));
    Basket.setOrderType(delivery.id);
    forwardTo(delivery.route);
  };

  checkForSingleDeliveryOption = async () => {
    const singleDeliveryOption = await getSingleDeliveryOption();
    if (singleDeliveryOption) {
      this.setDeliveryOption(singleDeliveryOption);
    }
  };

  getOrderingButtons = async () => {
    const delivery =
      isDefined(getConfig().api_config) && isDefined(getConfig().delivery)
        ? getConfig().delivery
        : [];
    this.setState({ delivery });
  };

  componentDidMount() {
    this.props.dispatch(getSocials());
    this.setState({
      delivery:
        isDefined(getConfig().api_config) && isDefined(getConfig().delivery)
          ? getConfig().delivery
          : [],
    });
    Basket.reset();
    this.checkForSingleDeliveryOption();
    this.getOrderingButtons();
  }

  P() {
    this.checkForSingleDeliveryOption();
  }

  openReserveTable() {
    const { social } = this.props;
    if (social && social['reservations']) {
      window.open(social['reservations'], '_system', 'location=yes');
    }
  }

  render() {
    const { __ } = this.props;
    return (
      <Layout hideSecondToolbar={true} color noPadding={true} darkenBaground={true}>
        <IonCard className="delivery-options-card">
          <IonCardHeader className="ion-text-center">
            <Title className="card-title">{__('Start New Order')}</Title>
          </IonCardHeader>

          <IonCardContent className="delivery-options-menu">
            <IonList lines="none">
              {this.state.delivery.map(
                (d, index) =>
                  !d.isRemoved && (
                    <IonItem
                      key={'delivery-option-' + index}
                      color="secondary"
                      disabled={d.isDisabled}
                      className="delivery-options-item"
                      onClick={() => this.setDeliveryOption(d)}
                    >
                      <div tabIndex="-1"></div>
                      <IonLabel>{__(d.label)}</IonLabel>
                    </IonItem>
                  ),
              )}
              <IonItem
                className="delivery-options-item"
                key={'reserve-table'}
                color="secondary"
                onClick={() => this.openReserveTable()}
              >
                <div tabIndex="-1"></div>
                <IonLabel>{__('Reserve a Table')}</IonLabel>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { social } = state.common;
  const { deliveryOption } = state.orders;
  return {
    social,
    deliveryOption,
  };
};

export default connect(stateToProps)(withTranslation(DeliveryOptions));
