import React from 'react';
import { connect } from 'react-redux';
import {
  IonItem,
  IonInput,
  IonButton,
  IonLabel,
  IonText,
  IonAlert,
  IonRow,
  IonCol,
  IonGrid,
} from '@ionic/react';
import Layout from '../../components/layout';
import { NormalText, SmallText, Spacer, StrongText, Title } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import { forwardTo, isEmptyObject } from '../../lib/utils';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import './index.css';
import Mobiscroll from '../../components/mobiscroll';
import { showToast } from '../../store/actions';
import { SET_TABLE_PAY_METHOD } from '../../store/constants';
const { SelectOption } = Mobiscroll;

const { getRestaurant, setPortionToPay, getTableAmountToPay, setTablePayMethod } = Basket;

class SplitPay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalBillMembers: null,
      portion: null,
      splitPayByMembers: true,
      amountToPay: null,
      isSetPayMethodModalOpen: false,
      payEventsVisible: false,
    };
  }
  componentDidMount() {
    if (Basket.bill_data.pay_method != '1') {
      forwardTo('/table-bill-pay');
    }
  }
  setTablePayMethod = () => {
    this.props.dispatch({
      type: SET_TABLE_PAY_METHOD,
      data: {
        pay_method: 2,
        table_bill_id: Basket.bill_data.id,
      },
    });
  };

  handleInput = (key, val) => {
    if (key == 'totalBillMembers') {
      Basket.setTableMembers(val);
    }
    this.setState({ [key]: val });
  };

  selectPortion = (event, data) => {
    const portion = data.getVal();
    Basket.setPortionToPay(portion);
    this.setState({ portion });
  };

  formatDataForSelect = () => {
    let billMembers = 0;
    if (Basket.table_members_paid == 0) {
      billMembers = Basket.getTableMembers();
    } else {
      billMembers = Basket.table_members - Basket.table_members_paid;
    }
    const { __ } = this.props;
    let arrForSelect = [{ text: __(`Select amount`), value: null }];
    for (let i = 0; i < billMembers; i++) {
      arrForSelect.push({ text: __(`I will pay for ${i + 1} person`), value: i + 1 });
    }
    return arrForSelect;
  };

  changePayMethod = () => {
    this.handleInput('splitPayByMembers', !this.state.splitPayByMembers);
    if (this.state.splitPayByMembers) {
      this.setState({ amountToPay: 0 });
    } else {
      this.setState({ totalBillMembers: null, portion: null });
    }
  };

  handleSubmit = () => {
    forwardTo('/bill-summary');
  };

  render() {
    const { totalBillMembers, portion, splitPayByMembers, amountToPay } = this.state;
    const deliveryOption = Basket.getDeliveryOption();
    const { __, total } = this.props;
    return (
      <Loading transparent>
        <Layout headerTitle={__(deliveryOption ? deliveryOption.label : '')} noPadding>
          <div className="flex-row-wrapper absolute-content">
            <div className="scrollable-y">
              <div className="flex-min">
                <Title>
                  <strong>{__('Split the bill')}</strong>
                </Title>
              </div>
              <IonGrid>
                <IonRow>
                  <IonCol>{__('Bill total')}</IonCol>
                  <IonCol className="righted">{Basket.getTotalBill()}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {__('Already paid')}{' '}
                    {Basket.getPayEvents()?.length > 0 && (
                      <SmallText
                        className="pointer underlined"
                        onClick={() =>
                          this.setState({ payEventsVisible: !this.state.payEventsVisible })
                        }
                      >
                        {__('view details')}
                      </SmallText>
                    )}
                  </IonCol>
                  <IonCol className="righted">{Basket.getPaidAmount()}</IonCol>
                </IonRow>
                {this.state.payEventsVisible && (
                  <>
                    {Basket.getPayEvents().map((el, i) => (
                      <IonRow ket={i}>
                        <IonCol>
                          <StrongText className="bold">{el.user_id}</StrongText>
                        </IonCol>
                        <IonCol class="righted">
                          <StrongText className="bold">
                            {Basket.formatPrice(el.payment_amount / 100)}
                          </StrongText>
                        </IonCol>
                      </IonRow>
                    ))}
                  </>
                )}

                <IonRow>
                  <IonCol>{__('TOTAL LEFT TO PAY')}</IonCol>
                  <IonCol className="righted">{Basket.getAmountLeftToPay()}</IonCol>
                </IonRow>
              </IonGrid>
              <div className="centered ion-margin-top">
                <NormalText>{__(`Split ${Basket.getTotalBill()} by:`)}</NormalText>
                <IonItem lines="none" className="box-wrapper inline-box-wrapper">
                  <IonInput
                    className="split-number-input"
                    onIonInput={(e) => this.handleInput('totalBillMembers', e.target.value)}
                    clearInput
                    required={true}
                    type="number"
                    pattern="number"
                    inputmode="number"
                    value={Basket.getTableMembers()}
                    disabled={true}
                  />
                  <div>
                    <NormalText>{__('People')}</NormalText>
                  </div>
                </IonItem>
              </div>
              <Spacer size={1} />
              <div className="box-wrapper box-wrapper-with-dropdown">
                <SelectOption
                  display="center"
                  onSet={(e, a) => this.selectPortion(e, a)}
                  data={this.formatDataForSelect()}
                  cssClass="select-portion"
                  value={Basket.table_members_to_pay}
                  placeholder={__('Select Potion')}
                  disabled={Basket.getTableMembers() ? false : true}
                />
              </div>

              <div className="flex-min split-pay-wrapper">
                <IonButton
                  // disabled={ totalBillMembers && portion && (tableData && tableData.pay_another_amount == false) ? false : true}
                  onClick={this.handleSubmit}
                  expand="block"
                  className="round-button ion-margin-top"
                  disabled={!Basket.table_members_to_pay}
                >
                  {__('Pay')}{' '}
                  {Basket.table_members_to_pay
                    ? Basket.formatPrice(Basket.getTableAmountToPay())
                    : ''}
                </IonButton>

                <IonButton
                  onClick={() => this.setState({ isSetPayMethodModalOpen: true })}
                  expand="block"
                  fill="clear"
                  className="underlined ion-margin-top"
                >
                  {__('Or pay another amount')}
                </IonButton>
              </div>
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={this.state.isSetPayMethodModalOpen}
          onDidDismiss={() => this.setState({ isSetPayMethodModalOpen: false })}
          header={__('Are you sure you want to set this?')}
          // message={__(this.getRestaurantDataMessage(__))}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.setState({ isSetPayMethodModalOpen: false }),
            },
            {
              text: __('Confirm'),
              role: 'submit',
              cssClass: 'secondary',
              handler: () => this.setTablePayMethod(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile, auth } = state.profile;
  const { checkedLocationCodeData } = state.orders;
  const { restaurants, selectedRestaurant, tableData } = state.restaurants;

  return {
    auth,
    profile,
    checkedLocationCodeData,
    restaurants,
    cards: state.orders.cards || [],
    selectedRestaurant: selectedRestaurant || {},
    tableData: tableData,
  };
};

export default connect(stateToProps)(withTranslation(SplitPay));
