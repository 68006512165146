import React, { Component } from 'react';
import { IonGrid, IonRow, IonCol, IonBadge } from '@ionic/react';
import { SmallText, Spacer, Title } from '../common';
import { makeKey, isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { removeVoucher, setModal } from '../../store/actions';
import Basket from '../../lib/basket';
import './index.css';
import { withRouter } from 'react-router';
import Modal from '../../components/modal';
import { connect } from 'react-redux';

class OrderContent extends Component {
  drawSubItems = (basketItem = {} /*, basketItemIndex*/) => {
    const { selectedChoices } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;

    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku, quantity } = choice;
          return (
            <IonRow
              key={makeKey(
                choiceGroupIndex,
                choiceIndex,
                sku,
              )} /*onClick={ () => this.handleOrderItemClick(basketItem, basketItemIndex) }*/
            >
              <IonCol size="50px"></IonCol>
              <IonCol className="paddLR grow">
                {quantity || 1} x {Basket.getProductName(choice, profile)}
              </IonCol>
              <IonCol className="righted paddLR">
                {basketInstance.calculateSubItemPrice(choice, quantity) !== 0
                  ? basketInstance._calculateSubItemPrice(choice, quantity)
                  : null}
              </IonCol>
            </IonRow>
          );
        });
      });
    }
    return null;
  };

  render() {
    const {
      handleOrderItemClick,
      __,
      history,
      profile,
      textColor,
      dispatch,
      isRemoveVoucherModalOpen,
    } = this.props;
    const basketInstance = this.props.basketInstance || Basket;
    const type = this.props.type || 'order';
    const appliedPoints = basketInstance.getAppliedPoints();
    const deliveryPrice = basketInstance.getDeliveryPrice();
    const deliveryPriceUnformated = basketInstance._getDeliveryPrice();
    const appliedVoucher = basketInstance.getAppliedVocuher();
    const paidAmount = basketInstance.getAlreadyPaidAmount();
    let location =
      history && history.location && history.location.pathname === '/history-details'
        ? true
        : false;

    return (
      <IonGrid className="order-content-wrapper paddLR">
        {basketInstance.getItems().map((basketItem, basketItemIndex) => {
          const { quantity, item, instructions } = basketItem;
          return (
            <div key={basketItemIndex} className="basket-item-wrapper">
              <IonRow
                key={makeKey(basketItemIndex, item.sku)}
                onClick={() => {
                  if (handleOrderItemClick) {
                    handleOrderItemClick(basketItem, basketItemIndex);
                  }
                }}
              >
                <IonCol className="paddLR grow">
                  {quantity} x {Basket.getProductName(item, profile)}
                </IonCol>
                <IonCol className="righted paddLR">
                  {basketInstance.calculateItemPriceByIndex(basketItemIndex, false) > 0
                    ? basketInstance._calculateItemPriceByIndex(basketItemIndex, false)
                    : null}
                </IonCol>
              </IonRow>
              {this.drawSubItems(basketItem, basketItemIndex)}
              {instructions && instructions !== '' ? (
                <IonRow>
                  <IonCol>
                    <SmallText>{__('Special Notes')}:</SmallText>
                  </IonCol>
                  <IonCol className="righted instruction-grow">
                    <SmallText>{instructions}</SmallText>
                  </IonCol>
                </IonRow>
              ) : null}
            </div>
          );
        })}

        {appliedVoucher &&
          appliedVoucher.length > 0 &&
          appliedVoucher.map((voucher) => {
            let voucherName = voucher.name;
            if (voucher && voucher.reward && voucher.reward.name) {
              voucherName = voucher.reward.name;
            }
            return (
              <div key={voucher.id}>
                <Spacer size={1} />
                <Title className="centered">{__('Vouchers')}</Title>
                <IonRow
                  className="bordered-bottom"
                  onClick={() =>
                    location ? null : dispatch(setModal('isRemoveVoucherModalOpen', true))
                  }
                >
                  <IonCol className="paddLR grow">{__(voucherName)} </IonCol>
                  <IonCol className="righted paddLR">
                    {basketInstance._calculateAppliedVocuhersPrice(true, null)}
                  </IonCol>
                </IonRow>
                <IonRow className="bordered-bottom">
                  <IonCol className="paddLR">
                    <SmallText color="gray" className="uppercase paddLR">
                      {__('Subtotal')}
                      <IonBadge className="small" slot="end">
                        i
                      </IonBadge>
                    </SmallText>
                  </IonCol>
                  <IonCol className="righted paddLR">{basketInstance._getSubTotal()}</IonCol>
                </IonRow>
                <Modal
                  className="remove-apply-voucuher"
                  title={__('Do you want to remove voucher') + ' ?'}
                  actionLabel={__('Yes')}
                  action={() => dispatch(removeVoucher(voucher.id))}
                  isOpen={!!isRemoveVoucherModalOpen}
                  onDidDismiss={() => dispatch(setModal('isRemoveVoucherModalOpen', false))}
                  noContent={true}
                />
              </div>
            );
          })}
        {deliveryPriceUnformated > 0 ? (
          <IonRow className="bordered-bottom">
            <IonCol className="paddLR grow text-start">{__('Delivery Charge')}</IonCol>
            <IonCol className="righted paddLR">{deliveryPrice}</IonCol>
          </IonRow>
        ) : null}
        {isDefined(appliedPoints) && appliedPoints > 0 ? (
          <>
            <IonRow
              className="bordered-bottom"
              onClick={() => {
                if (type === 'order' && history) {
                  history.push('/apply-points');
                }
              }}
            >
              <IonCol className="paddLR grow">
                <SmallText color={textColor || 'white'}>
                  {appliedPoints}&nbsp;{__('Points Redeem')}
                </SmallText>
              </IonCol>
              <IonCol className="righted paddLR">
                <SmallText color={textColor || 'white'}>
                  {basketInstance._calculatePointsAppliedPrice(null, true)}
                </SmallText>
              </IonCol>
            </IonRow>
          </>
        ) : null}
        {isDefined(paidAmount) && paidAmount > 0 ? (
          <IonRow className="bordered-bottom">
            <IonCol className="paddLR grow">
              <SmallText color={textColor || 'white'}>{__('Already paid')}</SmallText>
            </IonCol>
            <IonCol className="righted paddLR">
              <SmallText color={textColor || 'white'}>
                -{basketInstance._getAlreadyPaidAmount()}
              </SmallText>
            </IonCol>
          </IonRow>
        ) : null}
        <IonRow>
          <IonCol className="paddLR">
            <SmallText color={textColor || 'white'} className="uppercase paddLR">
              {__('Total')}
            </SmallText>
          </IonCol>
          <IonCol className="righted paddLR">
            <SmallText color={textColor || 'white'}>{basketInstance._getTotal(true)}</SmallText>
          </IonCol>
        </IonRow>
        {/* <IonRow>
					<IonCol>
						<SmallText color="gray" className="uppercase">
							{ type === 'order' ?
								<>
									{ __('Subtotal') }
									<IonBadge className="small" slot="end">i</IonBadge>
								</> : __('Total')
							}
						</SmallText>
					</IonCol>
					<IonCol className="righted">{ basketInstance._getTotal() }</IonCol>
				</IonRow> */}
      </IonGrid>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    isRemoveVoucherModalOpen: store.profile.isRemoveVoucherModalOpen,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderContent)));
