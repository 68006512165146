import React from 'react';
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonGrid,
  IonCol,
  IonRow,
} from '@ionic/react';
import Loading from '../../components/spinner';
import { pencilOutline } from 'ionicons/icons';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, SmallText, Sectiontitle, Spacer, NormalText } from '../../components/common';
import Basket from '../../lib/basket';
import ValidateButton from '../../components/validateButton';
import { getConfig } from '../../appConfig';
import Modal from '../../components/modal';
import Incrementer from '../../components/incrementer';
import { connect } from 'react-redux';
import BillContent from '../../components/billContent';
import {
  forwardTo,
  validateProfileData,
  goBack,
  go,
  isDefined,
  isWebConfig,
} from '../../lib/utils';
import { setModal } from '../../store/actions';
import { PillGroup } from '../../components/pill';
import ContentHeader from '../../components/contentHeader';
import './index.css';
import { withRouter } from 'react-router';

const {
  getPickUpPoint,
  getDeliveryAddress,
  changeItemQuantity,
  itemsCount,
  setMobile,
  getMobile,
  getTotal,
  getAllergen,
  getItems,
  isMinimumOrderTotalSatisfied,
  getDeliveryOption,
} = Basket;

class OrderSummary extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    this.state = {
      quantityModal: null,
      mobile: getMobile() || '',
      allergenModalOpen: false,
      billItems: [],
    };
  }

  handleInput = (key, val) => {
    this.setState({ [key]: val });
    setMobile(val);
  };

  handleSubmit = () => {
    const { cards } = this.props;
    if (cards && cards.length >= 1) {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        forwardTo('/checkout', { fromTablePay: true });
        setMobile(this.props.profile.mobile);
      } else {
        forwardTo('/contact-details', { fromTablePay: true });
      }
    } else {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        setMobile(this.props.profile.mobile);
        forwardTo('/checkout', { fromTablePay: true });
      } else {
        forwardTo('/contact-details', { fromTablePay: true });
      }
    }
  };

  handleOrderItemClick = (item, index) => {
    this.setState({ quantityModal: { item, index } });
  };

  updateItemQuantity = () => {
    const { item, index } = this.state.quantityModal;
    if (this.state.quantityModal) {
      changeItemQuantity(index, item.quantity);
      this.setState({ quantityModal: null });
    }
  };

  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantityModal: {
        ...this.state.quantityModal,
        item: {
          ...this.state.quantityModal,
          quantity: newQuantity,
        },
      },
    });
  };
  setBillPayServiceCharge(e, service_charge) {
    e.stopPropagation();
    document
      .querySelectorAll('.bill-pay-service-charge-button')
      .forEach((el) => el.classList.remove('is-active'));
    e.target.classList.add('is-active');
    Basket.setBillPayServiceCharge(service_charge);
  }
  render() {
    const { __, profile, dispatch, auth } = this.props;
    const { quantityModal, allergenModalOpen } = this.state;
    const allergens = getAllergen() || [];

    return (
      <Loading>
        <Layout color="white" headerTitle={__('Order Summary')}>
          <div className="absolute-content flex-row-wrapper">
            <div className="scrollable-y checkout">
              <Title>{__('Payment Summary')}</Title>
              <Spacer />
              <IonGrid>
                <IonRow>
                  <IonCol>{__('Order Total')}</IonCol>
                  <IonCol className="righted">
                    {Basket.formatPrice(Basket.table_payment_amount / 100)}
                  </IonCol>
                </IonRow>
              </IonGrid>

              <div className="bill-pay-service-charge-wrapper">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}
                >
                  <NormalText>{__('Add a service charge?')}</NormalText>
                  <NormalText>{Basket.formatPrice(Basket.service_charge_applied / 100)}</NormalText>
                </div>
                <div>
                  {getConfig().general.billPayServiceChargeValues.map((el) => {
                    return (
                      <IonButton
                        className={`bill-pay-service-charge-button ${
                          Basket.bill_pay_service_charge == el && 'is-active'
                        }`}
                        onClick={(e) => this.setBillPayServiceCharge(e, el)}
                        color="secondary"
                      >
                        {el == 0 ? <>{__('No, thanks')}</> : <>{el}%</>}
                      </IonButton>
                    );
                  })}
                </div>
              </div>

              <IonGrid>
                <IonRow>
                  <IonCol>{__('Total To Pay')}</IonCol>
                  <IonCol className="righted">
                    {Basket.formatPrice(
                      (Basket.service_charge_applied + Basket.table_payment_amount) / 100,
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
              {/* {Basket.getItems().length > 0 ? (
                <ContentHeader
                  __={__}
                  deliveryOption={getDeliveryOption()}
                  orderType={labelTitle}
                  deliveryAddress={getDeliveryAddress()}
                  pickUpPoint={getPickUpPoint()}
                />
              ) : null} */}
              {/* {this.state.billItems.length > 0 ? (
                <BillContent tableItems={this.state.billItems} />
              ) : (
                <IonItem>
                  <div
                    tabIndex="-1"
                    className="sectiontitle"
                    style={{ width: '100vh', textAlign: 'center' }}
                  >
                    {__('No items')}
                  </div>
                </IonItem>
              )} */}
              {/* <div className="validate-content">
                <ValidateButton />
                {isAuth && !valid && getConfig().appType.hasEmailValidationEnabled ? (
                  <div className="verified-content">
                    <SmallText color="grey">
                      {__('You can earn, but not redeem points until your account is verified')}
                    </SmallText>
                  </div>
                ) : null}
              </div> */}
            </div>
            <div className="flex-min">
              {/* {allergens.length > 0 ? (
                <IonButton
                  fill="clear"
                  expand="block"
                  className="link underlined"
                  color="dark"
                  onClick={() => this.setState({ allergenModalOpen: true })}
                >
                  {__('View allergen information')}
                </IonButton>
              ) : null} */}
              {/* <IonButton
                disabled={itemsCount() === 0 }
                className={`round-button redeem-points-btn ${
                  itemsCount() === 0 || !valid ? 'disabled' : ''
                }`}
                onClick={() => {
                  if ((itemsCount() === 0 || !valid) && isAuth) {
                    dispatch(setModal('isVerfiedModalOpen', true));
                  } else {
                    forwardTo('/apply-vouchers');
                  }
                }}
                expand="block"
                fill="outline"
                color="dark"
              >
                {appliedVouchers && appliedVouchers.length > 0
                  ? __('Replace Voucher').toUpperCase()
                  : __('Apply Vouchers').toUpperCase()}
              </IonButton>
              <IonButton
                disabled={this.state.billItems.length === 0 || !valid}
                onClick={() => forwardTo('/apply-points')}
                className="round-button "
                expand="block"
                fill="outline"
                color="primary"
              >
                {__('Redeem Points').toUpperCase()}
              </IonButton> */}
              <IonButton
                // disabled={Basket.getTablePaidItems().length === 0}
                onClick={this.handleSubmit}
                className="round-button "
                expand="block"
                color="secondary"
              >
                {__('Checkout').toUpperCase()}{' '}
                {Basket.formatPrice(
                  (Basket.service_charge_applied + Basket.table_payment_amount) / 100,
                )}
              </IonButton>
            </div>
          </div>

          {/* <Modal
            title={__('Change quantity')}
            action={this.updateItemQuantity}
            actionLabel={__('Change')}
            isOpen={!!quantityModal}
            onDidDismiss={() => this.setState({ quantityModal: null })}
          >
            {quantityModal && quantityModal.item ? (
              <Incrementer
                allowNegative={false}
                quantity={quantityModal.item.quantity}
                onUpdate={this.onIncrementerUpdate}
              />
            ) : null}
          </Modal>
          <Modal
            cssClass="allergen-modal"
            isOpen={allergenModalOpen}
            onDidDismiss={() => this.setState({ allergenModalOpen: false })}
          >
            <div className="absolute-content flex-row-wrapper">
              <div className="scrollable-y rhino">
                <Title className="centered">{__('Allergen Information')}</Title>
                <Spacer />
                {getItems().map((item, index) => {
                  let allAllergens = [];
                  let data = allergens.find((allergen) => allergen[1].sku === item.item.sku);
                  if (data && data.length > 0) {
                    allAllergens.push(
                      <div key={'allergen-' + index}>
                        <Sectiontitle>
                          {item.quantity}x {item.item.productName}
                        </Sectiontitle>
                        <PillGroup items={data[0].allergens} borderColor="primary" />
                        <Spacer />
                      </div>,
                    );
                  }
                  // check allergens for sub-items
                  if (isDefined(item.selectedChoices)) {
                    item.selectedChoices.forEach((group) => {
                      group.forEach((item) => {
                        if (
                          isDefined(item.itemRichData) &&
                          isDefined(item.itemRichData.allergenCodes) &&
                          item.itemRichData.allergenCodes.length > 0
                        ) {
                          let data1 = allergens.find((allergen) => allergen[1].sku === item.sku);
                          if (data1 && data1.length > 0) {
                            allAllergens.push(
                              <div key={'allergen_subitem_' + index + '_' + item.productName}>
                                <Sectiontitle>{item.productName}</Sectiontitle>
                                <PillGroup items={data1[0].allergens} borderColor="primary" />
                                <Spacer />
                              </div>,
                            );
                          }
                        }
                      });
                    });
                  }
                  return allAllergens;
                })}
              </div>
              <div className="flex-min centered">
                <Spacer />
                <IonButton
                  fill="clear"
                  className="link underlined"
                  color="dark"
                  onClick={() => this.setState({ allergenModalOpen: false })}
                >
                  {__('Hide allergen information')}
                </IonButton>
              </div>
            </div>
          </Modal> */}
        </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth } = store.profile;
  const { tableData } = store.restaurants;
  return {
    basketUpdated: store.orders.basketUpdated,
    profile: store.profile.profile,
    cards: store.orders.cards || [],
    itemAllergens: store.restaurants.itemAllergens,
    auth,
    tableData: tableData,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderSummary)));
