import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './store/reducers';
import rootSaga from './store/sagas';
import { getConfig } from './appConfig';
import { composeWithDevTools } from '@redux-devtools/extension';

const sagaMiddleware = createSagaMiddleware();

let middleware = applyMiddleware(sagaMiddleware);
if (getConfig().general.isReduxDevToolsOn) {
  middleware = composeWithDevTools(applyMiddleware(sagaMiddleware));
}

export const store = createStore(reducers, middleware);

sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
