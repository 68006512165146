import Dashboard from './screens/dashboard';
import Loyalty from './screens/loyalty';
import Vouchers from './screens/vouchers';
import Account from './screens/account';
import ReferAFriend from './screens/referAFriend';
import Feedback from './screens/feedback';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Locations from './screens/locations';
import Social from './screens/social';
import Login from './screens/login';
import Register from './screens/register';
import ResetPassword from './screens/resetPassword';
import Terms from './screens/terms';
import Privacy from './screens/privacy';
import Faq from './screens/faq';
import AllergensInfo from './screens/allergensInfo';
// import Order from './screens/order'
import ItemDetails from './screens/itemDetails';
import ClickAndCollect from './screens/clickAndCollect';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import OrderSummary from './screens/orderSummary';
import ApplyPoints from './screens/applyPoints';
import Checkout from './screens/checkout';
import OrderCompleted from './screens/orderCompleted';
import ApplyVouchers from './screens/applyVouchers';
import ContactDetails from './screens/contactDetails';
import DeliveryOptions from './screens/delivery-options';
import DeliveryTime from './screens/delivery-time';
import PickUpPoint from './screens/pick-up-point';
import PickUpPointCheck from './screens/pick-up-point-check';
import OrderToTable from './screens/orderToTable';
import DeliveryAddressCheck from './screens/delivery-address-check';
import DeliveryAddressAdd from './screens/delivery-address-add';
import Delivery from './screens/delivery';
import BillSummary from './screens/billSummary';
import BillCompleted from './screens/billCompleted';
import OrderAtTable from './screens/orderAtTable';
import PayTable from './screens/tablePay/index';
import SplitPay from './screens/tableSplitPay/index';
import SplitAmount from './screens/tableSplitAmount/index';

import PayByItems from './screens/tablePayItems/index';
import OrderConfirm from './screens/orderConfirmation';

import { getConfig } from './appConfig';
import { getSingleDeliveryOption, isIPad, isWebConfig } from './lib/utils';
import TableBillOpened from './screens/tableBillOpened';
import home from './assets/images/home-icon.svg';
import loyalty from './assets/images/loyalty-icon.svg';
import myAccount from './assets/images/myAccount-icon.svg';
import feedback from './assets/images/feedback-icon.svg';
import history from './assets/images/history-icon.svg';
import restaurants from './assets/images/location-icon.svg';
import social from './assets/images/social-icon.svg';
import login from './assets/images/login-icon_2.svg';
import logout from './assets/images/logout-icon.svg';
import settings from './assets/images/settings-icon.svg';
import referAFriend from './assets/images/refer-friend-icon.svg';
import voucher from './assets/images/voucher-icon.svg';
import startNewOrder from './assets/images/start-new-order-icon.svg';
import menu from './assets/images/menu.svg';

const Order = require('./screens/order' + (isWebConfig() ? 'Web' : '')).default;

export const getRoutes = async () => {
  const { hasCampaignManager, hasOrdering, hasLoyalty } = getConfig().appType;
  const singleDeliveryOption = getSingleDeliveryOption();
  const iPad = await isIPad();
  const web = isWebConfig();
  return {
    routes: [
      web
        ? []
        : {
            label: 'Home',
            path: '/dashboard',
            component: Dashboard,
            icon: home,
            exact: true,
            default: !iPad,
          },
      {
        label: 'Menu',
        path: '/order',
        component: Order,
        icon: menu,
        exact: true,
        default: web && !iPad,
      },
      ...(hasOrdering
        ? [
            {
              label: 'Start New Order',
              path: '/delivery-options',
              component: DeliveryOptions,
              default: iPad,
              icon: startNewOrder,
              protected: false,
              notInDrawer: !!singleDeliveryOption,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: singleDeliveryOption ? singleDeliveryOption.label : '',
              path: '/click-and-collect',
              component: ClickAndCollect,
              icon: startNewOrder,
              protected: false,
              notInDrawer: !singleDeliveryOption,
            },
          ]
        : []),
      // ...hasOrdering ? [{ label: 'Start New Order', path: '/click-and-collect', component: ClickAndCollect, icon: startNewOrder, protected: false }] : [],
      ...(hasLoyalty
        ? [
            {
              label: 'Loyalty',
              path: '/loyalty',
              component: Loyalty,
              icon: loyalty,
              protected: true,
              state: { tab: 'points' },
            },
          ]
        : []),
      ...(hasCampaignManager
        ? [
            {
              label: 'Vouchers',
              path: '/vouchers',
              component: Vouchers,
              icon: voucher,
              protected: true,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: singleDeliveryOption ? singleDeliveryOption.label : '',
              path: '/click-and-collect',
              component: ClickAndCollect,
              icon: startNewOrder,
              protected: false,
              notInDrawer: !singleDeliveryOption,
            },
          ]
        : []),
      //...hasOrdering ? [{ label: 'Start New Order', path: '/delivery-options', component: /*DeliveryOptions*/ null, icon: settings, protected: false, notInDrawer: !!singleDeliveryOption }] : [],
      ...(hasOrdering
        ? [
            {
              label: 'Checkout',
              path: '/checkout',
              component: Checkout,
              icon: settings,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Apply Points',
              path: '/apply-points',
              component: ApplyPoints,
              icon: settings,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),
      {
        label: 'My Account',
        path: '/account',
        component: Account,
        icon: myAccount,
        protected: true,
      },
      ...(hasCampaignManager
        ? [
            {
              label: 'Refer a friend',
              path: '/refer-a-friend',
              component: ReferAFriend,
              icon: referAFriend,
              protected: true,
            },
          ]
        : []),
      {
        label: 'Leave Feedback',
        path: '/feedback',
        component: Feedback,
        icon: feedback,
        protected: true,
      },
      { label: 'History', path: '/history', component: History, icon: history, protected: true },
      {
        label: 'Locations',
        path: '/locations',
        component: Locations,
        icon: restaurants,
        protected: false,
      },
      { label: 'Social Media', path: '/social', component: Social, icon: social, protected: false },
      {
        label: 'Contact Details',
        path: '/contact-details',
        component: ContactDetails,
        protected: true,
        notInDrawer: true,
      },
      {
        label: 'Apply Vouchers',
        path: '/apply-vouchers',
        component: ApplyVouchers,
        notInDrawer: true,
        protected: true,
      },
    ],
    authRoutes: [
      { label: 'Login', path: '/login', component: Login, icon: login, fn: 'login' },
      { label: 'Logout', path: '/logout', icon: logout, fn: 'logout' },
      { path: '/order-completed', component: OrderCompleted },
      { path: '/bill-completed', component: BillCompleted },
      { path: '/contact-details', component: ContactDetails },
    ],
    additionalRoutes: [
      { label: 'T&C', path: '/terms', component: Terms },
      { label: 'Privacy Policy', path: '/privacy', component: Privacy },
      { label: 'FAQ', path: '/faq', component: Faq },
    ],
    notInMenuRoutes: [
      { path: '/register', component: Register },
      { path: '/reset-password', component: ResetPassword },
      ...(hasOrdering ? [{ path: '/item-details', component: ItemDetails }] : []),
      ...(hasOrdering ? [{ path: '/order', component: Order }] : []),
      ...(hasOrdering ? [{ path: '/cards', component: Cards }] : []),
      ...(hasOrdering ? [{ path: '/card-add', component: CardAdd }] : []),
      ...(hasOrdering ? [{ path: '/history-details', component: HistoryDetails }] : []),
      ...(hasOrdering ? [{ path: '/order-summary', component: OrderSummary }] : []),
      ...(hasOrdering ? [{ path: '/bill-summary', component: BillSummary }] : []),
      ...(hasOrdering ? [{ path: '/table-opened', component: TableBillOpened }] : []),

      ...(hasOrdering ? [{ path: '/order-completed', component: OrderCompleted }] : []),
      ...(hasOrdering ? [{ path: '/delivery', component: Delivery }] : []),
      ...(hasOrdering
        ? [{ path: '/delivery-address-check', component: DeliveryAddressCheck }]
        : []),
      ...(hasOrdering ? [{ path: '/delivery-address-add', component: DeliveryAddressAdd }] : []),
      ...(hasOrdering ? [{ path: '/delivery-time', component: DeliveryTime }] : []),
      ...(hasOrdering ? [{ path: '/pick-up-point', component: PickUpPoint }] : []),
      ...(hasOrdering ? [{ path: '/pick-up-point-check', component: PickUpPointCheck }] : []),
      ...(hasOrdering ? [{ path: '/order-to-table', component: OrderToTable }] : []),
      ...(hasOrdering ? [{ path: '/order-to-table', component: OrderToTable }] : []),
      ...(hasOrdering
        ? [{ path: '/table-bill-pay', component: OrderAtTable, protected: true }]
        : []),
      ...(hasOrdering ? [{ path: '/pay-table', component: PayTable, protected: true }] : []),
      ...(hasOrdering ? [{ path: '/split-pay', component: SplitPay, protected: true }] : []),
      ...(hasOrdering ? [{ path: '/split-amount', component: SplitAmount, protected: true }] : []),

      ...(hasOrdering ? [{ path: '/pay-items', component: PayByItems, protected: true }] : []),
      ...(hasOrdering
        ? [{ path: '/order-confirm', component: OrderConfirm, protected: true }]
        : []),
    ],
  };
};
