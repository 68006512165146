import React, { Component } from 'react';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { Title, Spacer, BigLabel, NormalText, SmallText } from '../../components/common';
import pointsIcon from '../../assets/images/points-icon.svg';
import { formatNumber } from '../../lib/utils';

export class Points extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedIndex: null,
    };
  }

  toggleModal = (val, index) => {
    this.setState({ isOpen: val, selectedIndex: index });
  };

  render() {
    const { __, available_balance } = this.props;

    return (
      <div className="absolute-content points-tab">
        <div className="scrollable-y centered">
          <Spacer size={5} />
          <Title>{__('Loyalty')}</Title>
          <div className="points-image">
            <img src={pointsIcon} alt="" />
          </div>
          {/* <div className="loyalty-badge">
						<div>
							<IonIcon icon={ starOutline }/>
						</div>
					</div> */}
          <div className="points-footer">
            <BigLabel className="points">{formatNumber(available_balance)}</BigLabel>
            <NormalText>{__('Points Balance')}</NormalText>
            <Spacer size={1} />
            <SmallText>
              {__('Worth')}&nbsp;
              {Basket._calculatePointsAppliedPrice(available_balance, false, true)}
            </SmallText>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(Points);
