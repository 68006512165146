import {
  GET_RESTAURANTS,
  GET_REWARDS,
  GET_IKENTOO_MENU,
  BEFORE_CLOSE_TIME_PICKER,
  BEFORE_SHOW_TIME_PICKER,
  GET_RESTAURANTS_SNOOZED_DATA,
  GET_IKENTOO_MENUS_FOR_LOCATION,
  GET_BUSINESS_DATA,
  GET_TABLE_NUMBER,
  SET_TABLE_NUMBERS,
  GET_TABLE_DATA,
  GET_RESTAURANT_TABLE,
  SAVE_DELIVERY_DATA_TEMPORARILY,
  PASSED_ORDER
} from './constants';

export const getRestaurants = () => ({ type: GET_RESTAURANTS });

export const getRewards = () => ({ type: GET_REWARDS });

export const getBusinessData = () => ({ type: GET_BUSINESS_DATA });

export const getTableNumber = () => ({ type: GET_TABLE_NUMBER });

export const getIkentooMenu = (menuId, businessLocationId, redirect = true) => ({
  type: GET_IKENTOO_MENU,
  menuId,
  businessLocationId,
  redirect,
});

export const getIkentooMenusForLocation = (
  businessLocationId,
  additionalData = {},
  isDelivery,
) => ({ type: GET_IKENTOO_MENUS_FOR_LOCATION, businessLocationId, additionalData, isDelivery });

export const beforeShowTimePicker = () => ({ type: BEFORE_SHOW_TIME_PICKER });

export const beforeCloseTimePicker = () => ({ type: BEFORE_CLOSE_TIME_PICKER });

export const getRestaurantSnoozeData = () => ({ type: GET_RESTAURANTS_SNOOZED_DATA });

export const setTableNumbers = () => ({ type: SET_TABLE_NUMBERS });

export const getRestaurantTable = (restaurant, table) => ({
  type: GET_RESTAURANT_TABLE,
  restaurant,
  table,
});

export const saveDeliveryDataTemporarily = (tempData) => ({
  type: SAVE_DELIVERY_DATA_TEMPORARILY,
  tempData,
});

export const passedTheOrder = (isPassedOrder) => ({ type: PASSED_ORDER, isPassedOrder });
