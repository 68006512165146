import React, { Component } from 'react';
import { IonList, IonListHeader, IonItem, IonLabel } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, forwardTo } from '../../lib/utils';
import Basket from '../../lib/basket';
import { connect } from 'react-redux';
import NoData from '../../components/noData';

class orderList extends Component {
  componentDidMount() {
    setTimeout(() => {
      const item = document.querySelector('.order-list-items');
      if (item) {
        item.scrollTop = this.props.scrollTopPosition;
        item.scrollBy({ top: 1, behavior: 'smooth' });
      }
    }, 500);
  }

  itemList = (__, subCategoryItems, i) => {
    const { profile } = this.props;

    return (subCategoryItems || []).map((item, index) => {
      if (item.sku === undefined || !Basket.isProductEnabled(item)) {
        return <span key={item.sku + '_disabled'} />;
      }
      const image =
        item.itemRichData && item.itemRichData.squareImageUrl
          ? item.itemRichData.squareImageUrl
          : '';
      return (
        <IonItem key={item.sku + '_' + index + i} onClick={() => forwardTo('/item-details', item)}>
          <div className="order-list-item">
            <div tabIndex="-1" className="item-price">
              <span>{Basket.formatPrice(item.productPrice)}</span>
              {item.originalPrice ? (
                <>
                  <br />
                  <span className="original-price">&#163;{item.originalPrice}</span>
                </>
              ) : null}
            </div>
            <div className="item-content">
              <h5 className="mb-5">{__(Basket.getProductName(item, profile))}</h5>
              <div
                className="custom-line-height"
                dangerouslySetInnerHTML={{
                  __html: Basket.getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, ''),
                }}
              />
            </div>
            {image && image !== '' ? (
              <div className="item-image-wrapper">
                <div className="item-image" style={{ backgroundImage: 'url(' + image + ')' }} />
              </div>
            ) : null}
          </div>
        </IonItem>
      );
    });
  };

  drawCategoryItems = (__, category, items, breadCrumb = null, i, isGroup = false) => {
    let name = category.name ? breadCrumb + category.name : breadCrumb + '';
    let drawSubCategory = isGroup ? (
      <div ref={this.props.menuRefs[name]}>
        <IonListHeader className="order-sublist-header">
          <IonLabel>{name}</IonLabel>
        </IonListHeader>
        {this.subCategoriesList(__, category, breadCrumb + category.name)}
      </div>
    ) : (
      <>{items}</>
    );

    return <div key={category.type + '_' + name + i}>{drawSubCategory}</div>;
  };

  breadCrumb = (name) => {
    let breadCrumb = name !== '' ? name + ' | ' : name;
    return breadCrumb;
  };

  subCategoriesList = (__, category, name) => {
    if (!isDefined(category)) {
      return <NoData />;
    }
    const categoryItems = category.menuEntry || category.menuEntryGroups || null;
    let items = this.itemList(__, categoryItems);
    let breadCrumb = this.breadCrumb(name);
    if (categoryItems) {
      return categoryItems.map((subCategory, index) => {
        if (subCategory['@type'] === 'group') {
          items = this.itemList(__, subCategory.menuEntry, index);
          return this.drawCategoryItems(__, subCategory, items, breadCrumb, index, true);
        } else {
          items = this.itemList(__, [subCategory], index);
          return this.drawCategoryItems(__, subCategory, items, name, index, false);
        }
      });
    }
    return this.drawCategoryItems(__, category, items, name, 0);
  };

  calcHeight = () => {
    const { category } = this.props;
    let height = 0;
    if (this.ionList && this.orderListItems && isDefined(category)) {
      let orderListHeight = this.orderListItems.clientHeight;
      let lastCategory = this.ionList.childNodes[this.ionList.childNodes.length - 1];
      if (lastCategory) {
        let lastCategoryInnerWrap = lastCategory.childNodes[0];
        let nodes = lastCategoryInnerWrap.childNodes;
        let lastCategoryHeader = nodes[0];
        let lastCategoryItem = nodes[nodes.length - 1];
        if (orderListHeight && lastCategoryHeader && lastCategoryItem) {
          height =
            orderListHeight - (lastCategoryHeader.clientHeight + lastCategoryItem.clientHeight);
        }
      }
    }

    return height;
  };

  render() {
    const { __, category } = this.props;
    return (
      <div
        className="order-list-items"
        onScroll={(e) => this.props.selectCategoryOnScroll(e)}
        ref={(ref) => (this.orderListItems = ref)}
      >
        <IonList lines="none" ref={(ref) => (this.ionList = ref)}>
          {this.subCategoriesList(__, category, '')}
        </IonList>
        <div style={{ height: this.calcHeight() }}></div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    restaurantsUpdated: store.restaurants.restaurantsUpdated,
    basketUpdated: store.orders.basketUpdated,
  };
};

export default connect(mapStateToProps)(withTranslation(orderList));
