import React from 'react';
import { connect } from 'react-redux';
import { IonItem, IonSpinner, IonList, IonIcon, IonLabel } from '@ionic/react';
import { checkmarkCircle, closeCircle } from 'ionicons/icons';
import Layout from '../../components/layout';

const OrderConfirmation = ({ orderLoading, paymentLoading }) => {
  return (
    <Layout headerTitle={'Order Confirmation'} color="transparent" darkenBaground={false}>
      <IonList>
        <IonItem lines="none" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <IonLabel color="dark">Order processing</IonLabel>
          {orderLoading ? <IonSpinner /> : <IonIcon icon={checkmarkCircle} color="dark" />}
        </IonItem>
        <IonItem style={{ display: 'flex', justifyContent: 'space-between' }}>
          <IonLabel color="dark">Sending Payment</IonLabel>
          {paymentLoading === 'loading' ? (
            <IonSpinner />
          ) : paymentLoading === 'success' ? (
            <IonIcon color="dark" icon={checkmarkCircle} />
          ) : (
            <IonIcon color="dark" icon={closeCircle} />
          )}
        </IonItem>
      </IonList>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { orderLoading, paymentLoading } = state.orders;

  return {
    orderLoading,
    paymentLoading,
  };
};

export default connect(mapStateToProps, null)(OrderConfirmation);