import React from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonTextarea,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRadioGroup,
  IonRadio,
  IonAlert,
} from '@ionic/react';
import { withRouter } from 'react-router';
import { starOutline, star } from 'ionicons/icons';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { sendFeedback, setCommonModal } from '../../store/actions';
import { FieldError, Title, SmallText } from '../../components/common';
import { validateForm, isDefined, isWebConfig } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import './index.css';

const { SelectOption } = Mobiscroll;

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.resetState(),
    };
    this.formConfig = {
      food: { type: 'integer', required: true, invalidValue: 0 },
      service: { type: 'integer', required: true, invalidValue: 0 },
      selectedRestaurant: { type: 'select', required: true, invalidValue: null },
    };
  }

  resetState = () => ({
    food: 0,
    service: 0,
    customerService: true,
    techSupport: false,
    commentService: '',
    commentTech: '',
    radioGroup: 'customerService',
    selectedRestaurant: null,
    formErrors: {},
  });

  createStarSet(size, name) {
    return <>{[...Array(size).keys()].map((i) => this.createStar(name, i + 1))}</>;
  }

  createStar = (name, value) => {
    if (this.state[name] >= value) {
      return (
        <IonIcon
          key={value}
          icon={star}
          className="star"
          color="secondary"
          onClick={() => this.setValue(name, value)}
        />
      );
    } else {
      return (
        <IonIcon
          key={value}
          icon={starOutline}
          className="star"
          color="medium"
          onClick={() => this.setValue(name, value)}
        />
      );
    }
  };

  handleFeedback = () => {
    let formErrors = validateForm(this.formConfig, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const {
        food,
        service,
        commentService,
        commentTech,
        selectedRestaurant,
        techSupport,
        customerService,
      } = this.state;
      const feedback = {
        food,
        service,
        commentService,
        commentTech,
        selectedRestaurant,
        techSupport,
        customerService,
      };
      this.props.dispatch(sendFeedback(feedback));
      // reset state
      this.setState({ ...this.resetState() });
    }
  };

  selectRestaurant = (event, data) => {
    const selectedRestaurantId = data.getVal();
    this.setState({ selectedRestaurant: selectedRestaurantId });
    if (selectedRestaurantId !== null) {
      let formErrors = { ...this.state.formErrors };
      formErrors.selectedRestaurant = null;
      this.setState({ formErrors });
    }
  };

  setValue(field, value) {
    if (['customerService', 'techSupport'].indexOf(field) !== -1) {
      const decheckedKey = field === 'customerService' ? 'techSupport' : 'customerService';
      this.setState({ [field]: value, [decheckedKey]: false });
    } else {
      this.setState({ [field]: value });
    }
  }

  formatDataForSelect = (stores) => {
    const { __ } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (store.is_published && store.is_published === true) {
        arrForSelect.push({ text: store.name, value: store.id });
      }
    });
    return [{ text: __('Select Restaurant'), value: null }, ...arrForSelect];
  };

  handleChange = (event) => {
    this.setValue(event.detail.value, true);
    this.setState({
      radioGroup: event.detail.value,
    });
  };

  render() {
    const { __, isFeedbackModalOpen, restaurants } = this.props;
    const { techSupport, commentService, commentTech, formErrors } = this.state;
    const stores = restaurants.sort((a, b) => {
      if (isDefined(a.name) && isDefined(b.name)) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
      return 0;
    });

    return (
      <Loading transparent>
        <Layout headerTitle={__('Feedback')} scrollY={isWebConfig() ? false : true}>
          <Title>{__('Feedback')}</Title>
          <SmallText color="gray">
            {__('Please select the appropriate team and leave your feedback below')}
          </SmallText>
          {/* <IonItem>
						<IonLabel color="dark" position="floating">{ __('Location') }</IonLabel>
						<IonSelect onIonChange={ this.selectRestaurant } placeholder={ __('Select Restaurant') }>
							{ stores.map(restaurant => {
								const { id, name } = restaurant
								return (
								<IonSelectOption key={ 'store-option-' + id } value={ id }>{ name }</IonSelectOption>
							)})}
						</IonSelect>
					</IonItem> */}
          <label
            className="select-picker-label select-picker-label--feedback"
            htmlFor="demo-non-form"
          >
            {__('Location')}
          </label>

          <SelectOption
            className="select-picker-input select-picker-input--feedback"
            display="center"
            onSet={(e, a) => this.selectRestaurant(e, a)}
            data={this.formatDataForSelect(stores)}
            label="Location"
            value={this.state.selectedRestaurant}
            inputStyle="box"
            placeholder={__('Select Restaurant')}
            setText={__('OK')}
            cancelText={__('Cancel')}
          />
          {formErrors.selectedRestaurant ? (
            <FieldError className="field-error pad10" value={__(formErrors.selectedRestaurant)} />
          ) : null}
          <div className="box-holder box-holder--feedback box-holder-top">
            <IonList>
              <IonRadioGroup
                onIonChange={(event) => this.handleChange(event)}
                value={this.state.radioGroup}
              >
                <IonItem>
                  <div tabIndex="-1"></div>
                  <IonLabel>
                    <h2>{__('Customer Service')}</h2>
                    <SmallText color="gray">{__('Any comments related to your visit')}</SmallText>
                  </IonLabel>
                  <IonRadio
                    slot="end"
                    color="secondary"
                    className="top-small"
                    value={'customerService'}
                  />
                </IonItem>
                <IonItem lines="none">
                  <div tabIndex="-1"></div>
                  <IonLabel>
                    <h2>{__('Tech Support')}</h2>
                    <SmallText color="gray">{__('For technical help using our app')}</SmallText>
                  </IonLabel>
                  <IonRadio
                    slot="end"
                    color="secondary"
                    className="top-small"
                    value={'techSupport'}
                  />
                </IonItem>
              </IonRadioGroup>
            </IonList>
          </div>
          {techSupport ? (
            <div className="box-holder box-holder--feedback top-medium pad10 tech-box-holder">
              <div className="feedback-label">{__('Additional Comments')}</div>
              <div className="commentTech">
                <IonTextarea
                  value={commentTech}
                  onIonChange={(event) => this.setValue('commentTech', event.detail.value)}
                ></IonTextarea>
              </div>
              <div className="separator"></div>
              <br />
            </div>
          ) : (
            <div className="box-holder box-holder--feedback top-medium pad10">
              <div className="feedback-label">{__('Rate Our Food')}</div>
              <div style={{ marginBottom: 10 }}>{this.createStarSet(5, 'food')}</div>
              <FieldError className="field-error pad5l" value={__(this.state.formErrors.food)} />
              <div className="feedback-label">{__('Rate Our Service')}</div>
              <div style={{ marginBottom: 10 }}>{this.createStarSet(5, 'service')}</div>
              <FieldError className="field-error pad5l" value={__(this.state.formErrors.service)} />
              <div className="feedback-label">{__('Additional Comments')}</div>
              <div className="commentService">
                <IonTextarea
                  value={commentService}
                  onIonChange={(event) => this.setValue('commentService', event.detail.value)}
                ></IonTextarea>
              </div>
              <div className="separator"></div>
              <br />
            </div>
          )}
          <br />
          <IonButton
            expand="block"
            color="secondary"
            className="round-button"
            onClick={this.handleFeedback}
          >
            {__('Submit')}
          </IonButton>
        </Layout>
        <IonAlert
          isOpen={isFeedbackModalOpen}
          onDidDismiss={() => this.props.dispatch(setCommonModal('isFeedbackModalOpen', false))}
          header={__('Thank you')}
          message={__('Feedback is processed.')}
          buttons={[
            {
              text: __('OK'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                this.props.dispatch(setCommonModal(('isFeedbackModalOpen', false)));
                this.setState({
                  food: 0,
                  service: 0,
                  customerService: true,
                  techSupport: false,
                  commentService: '',
                  commentTech: '',
                });
              },
            },
          ]}
        />
      </Loading>
    );
  }
}
const stateToProps = (store) => {
  const { appVersion, isFeedbackModalOpen } = store.common;
  const { restaurants } = store.restaurants;
  return {
    appVersion,
    isFeedbackModalOpen,
    restaurants: restaurants || [],
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Feedback)));
